.card {
  border-radius: 19px;
  background: linear-gradient(137.69deg, #abffe2 0%, rgba(255, 217, 254, 0.86) 43.23%, #b3fff4 73.25%, #ddccff 100%);
  border: 1px solid #ffffff;
}
.card .title {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 14px 8px 11px;
}
.card .title h2 {
  font: 600 20px/26px MiSans-Semibold;
  color: #142d59;
  flex: 1;
}
.card .title span {
  font: 600 9px/12px MiSans-Semibold;
  color: #6a7ea1;
}
.card .content {
  border-radius: 19px;
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.8);
}
