@media screen and (max-width: 400px) {
  .price-content h3 {
    font: 500 12px/20px MiSans-Medium !important;
  }
}
.price {
  position: relative;
}
.price .name {
  position: absolute;
  z-index: 100;
  transform: translateY(-50%);
  background: linear-gradient(180deg, rgba(255, 249, 214, 0.2) 0%, rgba(252, 179, 181, 0.2) 100%), linear-gradient(180deg, rgba(255, 225, 224, 0.8) 0%, rgba(245, 76, 184, 0.8) 83.73%, rgba(255, 156, 219, 0.8) 100%), linear-gradient(180deg, #fff5c2 0%, #f43bb6 100%);
  padding: 5px 15px 4px 14px;
  font: 600 15px/20px MiSans-Semibold;
  color: #ffffff;
  border: 2px solid #cce6ff;
  border-radius: 15px;
  border-bottom-left-radius: 0;
}
.price .price-content {
  display: grid;
  background: linear-gradient(128.08deg, #e8f7ff 0%, #b3d5ff 100%);
  border: 2px solid #cce6ff;
  border-radius: 15px;
  padding-bottom: 10px;
}
.price .price-content .price-content_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-right-radius: 15px;
  margin-bottom: 10px;
}
.price .price-content .price-content_container .cover {
  width: 100%;
}
.price .price-content h3 {
  font: 500 13px/20px MiSans-Medium;
  color: #062263;
  text-align: center;
}
