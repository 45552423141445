.banner {
  cursor: pointer;
  user-select: none;
}
.bg-0 {
  background: url(assets/24.png) no-repeat, url(assets/25.png) no-repeat left -152px top -446px / 325px 446px;
  background-size: cover;
}
.bg-1 {
  position: relative;
  margin-top: -4rem;
  padding: 12px;
  padding-top: 0;
  padding-bottom: 120px;
  background: url(src/assets/21.png) no-repeat right -293px bottom -113px / 552px 420px, linear-gradient(#bff2ff, #baedff, #639afe);
}
.line-3 {
  border-radius: 19px;
  box-shadow: 0px 2px 4px 1px rgba(109, 171, 242, 0.5);
  background: linear-gradient(147.52deg, #abffe2 0%, rgba(255, 217, 254, 0.86) 43.23%, #b3fff4 73.25%, #ddccff 100%);
}
.line-3 .line-3_container {
  padding: 19px 17px 19px 18px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 19px;
  border: 1px solid #ffffff;
}
.line-3 .row-0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;
  border-radius: 19px;
  background: linear-gradient(180deg, rgba(105, 255, 252, 0.48) 0%, rgba(107, 203, 255, 0.5) 100%), linear-gradient(129.24deg, #f2fbff 0%, #fcfeff 100%);
  border: 0.5px solid #ffffff;
}
.line-3 .row-0 span {
  font: 500 14px/19px MiSans-Medium;
  color: #142d59;
  text-align: center;
  white-space: nowrap;
}
.line-3 .row-0 .column {
  display: grid;
}
.line-3 .row-1 {
  display: flex;
}
.line-3 .row-1:nth-of-type(2) {
  margin-top: 14px;
  margin-bottom: 11px;
}
.line-3 .row-1 h2 {
  white-space: nowrap;
  font: 600 16px/22px MiSans-Semibold;
  color: #100e5c;
}
.line-3 .row-1 span {
  font: 16px/22px MiSans-Normal;
  color: #100e5c;
}
.line-4 {
  margin-top: 32px;
}
.line-4 .column {
  display: grid;
  justify-content: center;
  gap: 12px;
  padding: 26px 0 20px;
}
.line-4 .column img {
  justify-self: center;
}
.line-4 .column span {
  justify-self: center;
  font: 16px/22px MiSans-Normal;
  color: #142d59;
}
.line-4 .column b {
  font-family: MiSans-Semibold;
  font-weight: 600;
}
.line-5 {
  margin-top: 34px;
}
.line-5 .column {
  display: grid;
  justify-content: center;
  padding: 27px 20px 25px;
}
.line-5 .column img {
  justify-self: center;
}
.line-5 .column .mfcj {
  margin: 9px 0 21px;
}
.line-6 {
  position: relative;
  margin-top: 45px;
}
.line-6 .line-6_icon {
  position: absolute;
  left: 47px;
  bottom: -83px;
}
.line-6 .jser {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin: 28px 0;
}
.line-6 .jser h2 {
  font: 600 17px/22px MiSans-Semibold;
}
.line-6 .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 12px;
  padding: 34px 24px;
  padding-top: 15px;
}
