.modal {
  position: fixed;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s;
}
.modal.slide-in {
  opacity: 1;
}
.modal .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.78);
}
.modal .container {
  display: grid;
  gap: 40px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 56px;
}
.modal .container .icon-close {
  justify-self: center;
  cursor: pointer;
}
