.noscroll {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

.mfcj {
  cursor: pointer;
  transition: transform 0.2s;
}
.mfcj:active {
  transform: scale(0.9);
}

/* 应该是 400 */
@font-face {
  font-family: MiSans-Normal;
  src: url(./assets/fonts/MiSans-Normal.ttf);
}

/* 应该是 500 */
@font-face {
  font-family: MiSans-Medium;
  src: url(./assets/fonts/MiSans-Medium.ttf);
}

/* 应该是 600 */
@font-face {
  font-family: MiSans-Semibold;
  src: url(./assets/fonts/MiSans-Semibold.ttf);
}